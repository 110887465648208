#title-scene-section {
  min-height: 100vh;
  max-width: unset;
  position: relative;
}

#title-scene-section #title-background-scene {
  z-index: 0;
  -webkit-user-select: none;
  user-select: none;
  background-color: #35a4cd;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

#title-scene-section #title-background-scene #grass-layer, #title-scene-section #title-background-scene #sun-layer, #title-scene-section #title-background-scene #clouds-layer, #title-scene-section #title-background-scene #birds-layer {
  position: absolute;
}

#title-scene-section #title-background-scene #birds-layer {
  z-index: 6;
  top: 15%;
  right: 10%;
}

#title-scene-section #title-background-scene #birds-layer img {
  width: 50px;
  position: relative;
}

#title-scene-section #title-background-scene #birds-layer img:nth-child(2) {
  width: 35px;
  transform: rotate(30deg);
}

#title-scene-section #title-background-scene #grass-layer {
  z-index: 5;
  width: 100%;
  height: 64%;
  background-image: url("wave3.0ef200e5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  transform: scaleX(-1);
}

#title-scene-section #title-background-scene #clouds-layer {
  z-index: 4;
  justify-content: space-evenly;
  display: flex;
  top: 45%;
  left: 0;
  right: 0;
}

#title-scene-section #title-background-scene #clouds-layer img {
  width: 1000px;
  display: block;
  position: relative;
}

#title-scene-section #title-background-scene #clouds-layer img:nth-child(1) {
  top: 100px;
  transform: scaleX(-1);
}

#title-scene-section #title-background-scene #clouds-layer img:nth-child(2) {
  width: 800px;
  top: 64px;
}

#title-scene-section #title-background-scene #clouds-layer img:nth-child(3) {
  transform: scaleY(-1)scaleX(-1);
}

#title-scene-section #title-background-scene #sun-layer {
  z-index: 3;
  width: 360px;
  height: 360px;
  background-color: gold;
  border-radius: 1000px;
  top: 100px;
  left: -70px;
  box-shadow: 0 0 10px gold;
}

#title-scene-section #title-text {
  z-index: 10;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#title-scene-section #title-text #mdma-text-cont {
  margin-top: 100px;
  position: relative;
}

#title-scene-section #title-text #mdma-text-cont h1 {
  text-align: center;
  letter-spacing: 2px;
  margin: 0;
  font-size: 600px;
  position: relative;
}

#title-scene-section #title-text #mdma-text-cont h1:first-of-type span {
  color: #fff;
}

#title-scene-section #title-text #mdma-text-cont h1:last-of-type {
  font-family: Londrina Shadow, cursive;
  position: absolute;
  top: 0;
}

#title-scene-section #title-text #mdma-text-cont h1:last-of-type span {
  color: #1e2019;
  transition: color .5s;
}

#title-scene-section #title-text #mdma-text-cont h1:last-of-type span:hover {
  color: gold;
}

#title-scene-section #title-text #mdma-desc {
  color: #fff;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 32px;
  font-family: Permanent Marker, cursive;
  font-size: 24px;
  line-height: 1.4;
}

#title-scene-section #title-text #sale-live-cont {
  margin: 36px auto 80px;
}

#title-scene-section #title-text #sale-live-cont span {
  color: #fff;
}

#title-scene-section #title-text #sale-live-cont #sale-buttons {
  align-items: center;
  display: flex;
}

#title-scene-section #title-text #sale-live-cont #sale-buttons #mint-btn {
  color: #ff4500;
  letter-spacing: 5px;
  border-color: #ff4500;
  padding: 12px 16px;
  font-size: 40px;
}

#title-scene-section #title-text #sale-live-cont #sale-info {
  color: #fff;
  text-align: center;
  margin-top: 16px;
  font-family: Permanent Marker, cursive;
  font-size: 16px;
}

@media (max-width: 1400px) {
  #title-scene-section #title-text #mdma-text-cont h1 {
    font-size: 500px;
  }

  #title-scene-section #title-text #mdma-desc {
    margin-top: 100px;
  }
}

@media (max-width: 1200px) {
  #title-scene-section #title-text #mdma-text-cont h1 {
    font-size: 400px;
  }

  #title-scene-section #title-text #mdma-desc {
    margin-top: 150px;
    font-size: 20px;
  }
}

@media (max-width: 900px) {
  #title-scene-section #title-text #mdma-text-cont h1 {
    font-size: 300px;
  }

  #title-scene-section #title-text #mdma-desc {
    margin-top: 300px;
  }
}

@media (max-width: 700px) {
  #title-scene-section {
    overflow: auto;
  }

  #title-scene-section #title-text {
    align-items: flex-start;
    position: relative;
  }

  #title-scene-section #title-text #mdma-text-cont {
    align-self: center;
    margin-top: 100px;
    padding-bottom: 40px;
  }

  #title-scene-section #title-text #mdma-text-cont h1 {
    text-align: left;
    width: 77px;
    word-break: break-all;
    font-size: 150px;
    line-height: 130px;
  }

  #title-scene-section #title-text #mdma-desc {
    margin-top: 36px;
    padding: 0;
  }

  #title-scene-section #title-text #sale-live-cont {
    margin-top: 32px;
  }
}

/*# sourceMappingURL=index.6421015f.css.map */
