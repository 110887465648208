@import "/src/constants";

#title-scene-section {
  position: relative;
  min-height: 100vh;
  max-width: unset;

  #title-background-scene {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $blue;
    z-index: 0;
    user-select: none;
    overflow: hidden;

    #grass-layer,
    #sun-layer,
    #clouds-layer,
    #birds-layer {
      position: absolute;
    }

    #birds-layer {
      z-index: 6;
      top: 15%;
      right: 10%;

      img {
        position: relative;
        width: 50px;

        &:nth-child(2) {
          width: 35px;
          transform: rotate(30deg);
        }
      }
    }

    #grass-layer {
      z-index: 5;
      bottom: 0;
      width: 100%;
      height: 64%;
      background-image: url("/src/assets/images/title-scene/wave3.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transform: scaleX(-1);
    }

    #clouds-layer {
      z-index: 4;
      left: 0;
      right: 0;
      top: 45%;
      display: flex;
      justify-content: space-evenly;

      img {
        position: relative;
        display: block;
        width: 1000px;

        &:nth-child(1) {
          top: 100px;
          transform: scaleX(-1);
        }

        &:nth-child(2) {
          width: 800px;
          top: 64px;
        }

        &:nth-child(3) {
          transform: scaleY(-1) scaleX(-1);
        }
      }
    }

    #sun-layer {
      z-index: 3;
      top: 100px;
      left: -70px;
      width: 360px;
      height: 360px;
      background-color: $yellow;
      border-radius: 1000px;
      box-shadow: 0 0 10px $yellow;
    }
  }

  #title-text {
    position: relative;
    z-index: 10;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #mdma-text-cont {
      position: relative;
      margin-top: 100px;

      h1 {
        position: relative;
        font-size: 600px;
        text-align: center;
        margin: 0;
        letter-spacing: 2px;

        &:first-of-type span {
          color: white;
        }

        &:last-of-type {
          position: absolute;
          top: 0;
          font-family: "Londrina Shadow", cursive;

          span {
            color: $dark-chocolate;
            transition: 0.5s color;

            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }

    #mdma-desc {
      font-family: "Permanent Marker", cursive;
      line-height: 1.4;
      color: white;
      font-size: 24px;
      text-align: center;
      padding: 0 32px;
      margin-left: auto;
      margin-right: auto;
    }

    #sale-live-cont {
      margin: 36px auto 80px;

      span {
        color: white;
      }

      #sale-buttons {
        display: flex;
        align-items: center;

        #mint-btn {
          color: $orange;
          border-color: $orange;
          font-size: 40px;
          letter-spacing: 5px;
          padding: 12px 16px;
        }
      }

      #sale-info {
        color: white;
        font-family: "Permanent Marker", cursive;
        text-align: center;
        margin-top: 16px;
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1400px) {
  #title-scene-section {
    #title-text {
      #mdma-text-cont h1 {
        font-size: 500px;
      }

      #mdma-desc {
        margin-top: 100px;
      }
    }
  }
}

@media (max-width: 1200px) {
  #title-scene-section {
    #title-text {
      #mdma-text-cont h1 {
        font-size: 400px;
      }

      #mdma-desc {
        margin-top: 150px;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 900px) {
  #title-scene-section {
    #title-text {
      #mdma-text-cont h1 {
        font-size: 300px;
      }

      #mdma-desc {
        margin-top: 300px;
      }
    }
  }
}

@media (max-width: 700px) {
  #title-scene-section {
    overflow: auto;

    #title-text {
      position: relative;
      align-items: flex-start;

      #mdma-text-cont {
        margin-top: 100px;
        padding-bottom: 40px;
        align-self: center;

        h1 {
          text-align: left;
          width: 77px;
          word-break: break-all;
          font-size: 150px;
          line-height: 130px;
        }
      }

      #mdma-desc {
        padding: 0;
        margin-top: 36px;
      }

      #sale-live-cont {
        margin-top: 32px;
      }
    }
  }
}
